import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

export interface IModal {
  title: string;
  text: string;
  labelButton: string;
}

@Component({
  selector: "app-privacy-data-modal",
  templateUrl: "./privacy-data-modal.component.html",
  styleUrls: ["./privacy-data-modal.component.scss"],
})

export class PrivacyDataModalComponent {
  @Input() public modal: IModal;

  constructor(public activeModal: NgbActiveModal) {}

  closeModal() {
    this.activeModal.close();
  }
}


