import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { User } from "../models/auth.models";
import { environment } from "../../../environments/environment";
import { BehaviorSubject, map, Observable, tap } from "rxjs";
import { UtilsService } from "./utils.service";
import { CookieService } from "ngx-cookie-service";
import { ICurrentUser } from "../models/current-user";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private user: ICurrentUser;
  private authUrl = `${environment.url}/auth`;

  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  get MEURH360_Admin() {
    return "Administrador MEURH360";
  }

  public getCurrentUserUpdates(): Observable<boolean> {
    return this.refresh.asObservable();
  }

  public setToRefresh(value: boolean): void {
    this.refresh.next(value);
  }

  public saveCurrentUser(user: any) {
    this.user = {
      payload: {
        ...user,
        name: user?.userName,
        profile: user?.userProfile?.name,
        role: user?.userProfile?.roles,
      },
    };
  }

  public get isFirstAccess(): boolean {
    const currentUser = this.currentUser()?.payload;
    return currentUser?.firstAccess;
  }

  public get userFranchises(): number[] {
    const user = this.currentUser()?.payload;
    return user?.branches || [];
  }

  public get canSendProfileToHR(): boolean {
    const user = this.currentUser()?.payload;
    return user?.employeeDataStatus != 2 && user?.employeeDataStatus != 3;
  }

  public get isPendingProfile(): boolean {
    const user = this.currentUser()?.payload;
    return user?.employeeDataStatus == 1;
  }

  public get nickname() {
    const user = this.currentUser()?.payload;
    return this.utilsService.getUserNickname(user.name);
  }

  public get employeeRoleId() {
    const user = this.currentUser()?.payload;
    return user.roleId;
  }

  public get Subordinates() {
    const user = this.currentUser()?.payload;
    return user.subordinatesId;
  }

  public userCanManage(branches: number[]): boolean {
    if (!this.userFranchises.length) return true;
    return branches.every((id) => this.userFranchises.includes(id));
  }

  public update() {
    return this.http
      .put(this.authUrl, {})
      .pipe(tap(() => this.setToRefresh(true)));
  }
  /**
   * Returns the current user
   */
  public currentUser(): ICurrentUser {
    return this.user;
  }

  public employeeId() {
    return this.currentUser()?.payload?.employeeId;
  }

  public get isNewUSerCompanyWasNotConfigured(): boolean {
    const user = this.currentUser();
    return (
      user?.payload?.profile !== this.MEURH360_Admin &&
      (user?.payload?.companyId == 0 || !user?.payload?.companyId)
    );
  }

  public get IsHiringProcess(): boolean {
    const user = this.currentUser();
    return user?.payload?.profile === "COLABORADOR_EM_ADMSSAO";
  }

  public get IsDefaultUser(): boolean {
    const user = this.currentUser();
    return user?.payload?.profile === "Usuario Colaborador";
  }

  public get IsHRUser(): boolean {
    const user = this.currentUser();
    return user?.payload?.profile === "Administrador Cliente";
  }

  public get isTeamLeader(): boolean {
    const user = this.currentUser();
    return user?.payload?.isTeamLead;
  }

  public get IsHRBP(): boolean {
    const user = this.currentUser();
    return user?.payload?.profile === "HRBP";
  }

  public get canManageEmployeeProfile(): boolean {
    const user = this.currentUser();
    return user?.payload?.role?.includes("ADMNISTRAR_ON_OFF_BOARD");
  }

  public get userRoles(): string[] {
    const user = this.currentUser();
    return user?.payload?.role;
  }

  public get userId(): number {
    const user = this.currentUser();
    return +user?.payload?.id;
  }

  public get profile(): string {
    const user = this.currentUser();
    return user?.payload?.profile;
  }
  public get IsManager(): boolean {
    const user = this.currentUser();
    return user?.payload?.isManager;
  }

  public get employeeSubordinates(): number[] {
    const user = this.currentUser();
    return user?.payload?.subordinatesId;
  }

  isAdmin(): boolean {
    const user = this.currentUser();
    return user?.payload?.profile === this.MEURH360_Admin;
  }

  public get registerWorkedHours(): boolean {
    const user = this.currentUser();
    return user?.payload?.registerWorkedHours;
  }

  get getCurrentUser() {
    return this.currentUser()?.payload;
  }
  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  public login(email: string, password: string) {
    return this.http.post(
      `${this.authUrl}/login`,
      {
        email: email,
        password: password,
      },
      { withCredentials: true }
    );
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  public register(
    username: string,
    email: string,
    password: string,
    confirmPassword: string,
    agreement: boolean
  ) {
    return this.http.post(`${environment.url}/accounts`, {
      fullName: username,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      agreement: agreement,
    });
  }

  /**
   * Reset password
   * @param email email
   */
  public resetPassword(email) {
    return this.http.post(`${this.authUrl}/reset`, email);
  }

  public canManagerAccessEmployeeData() {
    return this.http.get(
      `${environment.url}/v1/employees/access-employee-data`
    );
  }

  public canEditEmployeeArea(): Observable<boolean> {
    return this.http.get<boolean>(
      `${environment.url}/v1/employees/edit-area-enabled`
    );
  }

  public changePassword(payload, accessToken: string) {
    return this.http.put(`${this.authUrl}/reset`, payload, {
      headers: { Authorization: `bearer ${accessToken}` },
    });
  }
  /**
   * Logout the user
   */
  logout() {
    return this.http.post(`${this.authUrl}/logout`, {});
  }

  ssoLogin = (idp: string) =>
    `${environment.url.replace("api", "sso")}/saml/redirect?idp=${idp}`;

  getRefreshToken() {
    return this.http.post(`${this.authUrl}/refresh`, {});
  }

  getMe() {
    return this.http.get(`${this.authUrl}/me`);
  }

  getIdps() {
    return this.http
      .get(`${this.authUrl}/idps`)
      .pipe(tap((res) => console.log(res)));
  }
}
