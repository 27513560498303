import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  find,
  map,
  of,
  take,
  takeLast,
  tap,
} from "rxjs";
import { TenantStyleConfigModel } from "../../models/tenant-style-config.model";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth.service";

@Injectable({ providedIn: "root" })
export class TenantStore {
  private styleConfigSubject = new BehaviorSubject<TenantStyleConfigModel>(
    defaultStyle
  );

  
  public email: string;
  private url = `${environment.url}/v1/candidates`;
  

  private termsAndConditionsSubject = new BehaviorSubject<string>(null);

  public style$: Observable<TenantStyleConfigModel> =
    this.styleConfigSubject.asObservable();

  public termsAndConditions$: Observable<string> =
    this.termsAndConditionsSubject.asObservable();

  baseUrl = `${environment.url}/v1/tenants`;

  constructor(private http: HttpClient, private auth: AuthenticationService) { }

  getStyles(id?: number) {
    if (id) {
      this.http
        .get<TenantStyleConfigModel>(`${this.baseUrl}/${id}`)
        .pipe(
          map((data: any) =>
            data.map((item) => ({ key: item.key, value: item.value }))
          ),
          map((data) => data.map((item) => Object.values(item))),
          map((data) => {
            const config = {};
            data.forEach((item) => (config[item[0]] = item[1]));
            return config as TenantStyleConfigModel;
          }),
          tap((data) =>
            Object.keys(data).length
              ? this.styleConfigSubject.next({ ...defaultStyle, ...data })
              : this.styleConfigSubject.next(defaultStyle)
          )
        )
        .subscribe();
    }
  }

  setStyles(json: string, companyId: number) {
    const payload = JSON.parse(json);
    return this.http.post(`${this.baseUrl}/${companyId}`, payload);
  }

  setLogo(json: string, companyId: number) {
    const payload = JSON.parse(json);
    return this.http.post(`${this.baseUrl}/${companyId}/logos`, payload);
  }

  private hexToRgba(hex: string, alpha: number): string {
    hex = hex.replace(/^#/, '');
    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  applyStylePublic(id?: number) { 
    this.getStyles(id);
    this.style$.subscribe((style) => {
      document.documentElement.style.setProperty(
        "--vertical-menu-color",
        style.VerticalMenuColor || defaultStyle.VerticalMenuColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-color",
        style.VerticalMenuItemColor || defaultStyle.VerticalMenuItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-sub-item-color",
        style.VerticalMenuSubItemColor || defaultStyle.VerticalMenuSubItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-icon-color",
        style.VerticalMenuIconColor || defaultStyle.VerticalMenuIconColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-hover-color",
        style.VerticalMenuItemHoverColor ||
          defaultStyle.VerticalMenuItemHoverColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-active-color",
        style.VerticalMenuItemActiveColor ||
          defaultStyle.VerticalMenuItemActiveColor
      );
      document.documentElement.style.setProperty(
        "--horizontalbar-color",
        style.horizontalBarColor || defaultStyle.horizontalBarColor
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        style.PrimaryColor || defaultStyle.PrimaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        style.SecondaryColor || defaultStyle.SecondaryColor
      );
      document.documentElement.style.setProperty(
        "--gradient-color",
        style.GradientColor || defaultStyle.GradientColor
      );
      document.documentElement.style.setProperty(
        "--default-label-color",
        style.LabelColor || defaultStyle.LabelColor
      );
    });
  }

  applyStyle() {
    this.getStyles(this.auth.getCurrentUser?.companyId);
    this.style$.subscribe((style) => {
      document.documentElement.style.setProperty(
        "--vertical-menu-color",
        style.VerticalMenuColor || defaultStyle.VerticalMenuColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-color",
        style.VerticalMenuItemColor || defaultStyle.VerticalMenuItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-sub-item-color",
        style.VerticalMenuSubItemColor || defaultStyle.VerticalMenuSubItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-icon-color",
        style.VerticalMenuIconColor || defaultStyle.VerticalMenuIconColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-hover-color",
        style.VerticalMenuItemHoverColor ||
        defaultStyle.VerticalMenuItemHoverColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-active-color",
        style.VerticalMenuItemActiveColor ||
        defaultStyle.VerticalMenuItemActiveColor
      );
      document.documentElement.style.setProperty(
        "--horizontalbar-color",
        style.horizontalBarColor || defaultStyle.horizontalBarColor
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        style.PrimaryColor || defaultStyle.PrimaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        style.SecondaryColor || defaultStyle.SecondaryColor
      );
      document.documentElement.style.setProperty(
        "--gradient-color",
        style.GradientColor || defaultStyle.GradientColor
      );
      document.documentElement.style.setProperty(
        "--default-label-color",
        style.LabelColor || defaultStyle.LabelColor
      );

      const primaryColor = style.PrimaryColor || defaultStyle.PrimaryColor;
      const redPrimaryColor = this.hexToRgba(primaryColor, 0.3);
      document.documentElement.style.setProperty("--rgba-primary-color", redPrimaryColor);
    });
  }

  getTermsAndConditions = () =>
    this.http
      .get<TenantConfiguration>(
        `${this.baseUrl}/${this.auth.getCurrentUser?.companyId}/terms-and-conditions`
      )
      .pipe(tap((data) => this.termsAndConditionsSubject.next(data?.value)))
      .subscribe();
}

export const defaultStyle: TenantStyleConfigModel = {
  TenantName: "N/A",
  LogoBottom: "",
  LogoTop: "assets/images/meurh360-white-logo1.png",
  TenantCode: "",
  GradientColor: "",
  horizontalBarColor: "",
  PrimaryColor: "#2979FF",
  LabelColor: "#1B4EA3",
  SecondaryColor: "#74788d",
  VerticalMenuColor: "#2a3042",
  VerticalMenuItemColor: "#a6b0cf",
  VerticalMenuSubItemColor: "#79829c",
  VerticalMenuIconColor: "#6a7187",
  VerticalMenuItemHoverColor: "#FFFFFF",
  VerticalMenuItemActiveColor: "#FFFFFF",
  logoTopHeight: 30,
};

export interface TenantConfiguration {
  id: number;
  key: string;
  value: string;
  active: boolean;
  type: number;
}
