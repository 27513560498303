import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import ptBr from "@angular/common/locales/pt";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  NgbAccordionModule,
  NgbModule,
  NgbToast,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { CarouselModule } from "ngx-owl-carousel-o";

import { ExtrapagesModule } from "./extrapages/extrapages.module";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutsModule } from "./layouts/layouts.module";

import { registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgToggleModule } from "ng-toggle-button";
import { environment } from "src/environments/environment";
import { decryptInterceptor } from "./core/helpers/decrypt.Interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { LoaderInterceptor } from "./core/helpers/loader.interceptor";
import { CoverageModalComponent } from "./coverage-modal/coverage-modal.component";
import { PrivacyDataModalComponent } from "./privacy-data-modal/privacy-data-modal.component";
import { ModalCreateComponent } from "./pages/competence/modal-create/modal-create.component";
import { ModalDeleteComponent } from "./pages/competence/modal-delete/modal-delete.component";
import { ModalEditComponent } from "./pages/competence/modal-edit/modal-edit.component";
import { TooltipModalComponent } from "./pages/competence/tooltip-modal/tooltip-modal.component";
import { DeleteModalComponent } from "./portal-admission/delete-modal/delete-modal.component";
import { IncluirModalComponent } from "./portal-admission/incluir-modal/incluir-modal.component";
import { ApprovalModalComponent } from "./portal-admission/metlife/approval-modal/approval-modal.component";
import { DependensAccordionNewComponent } from "./portal-admission/metlife/dependents-accordion-new/dependens-accordion-new.component";
import { PortalAdmissionModule } from "./portal-admission/portal-admission.module";
import { CreateServiceComponent } from "./service-collaborative/create-service/create-service.component";
import { ServiceStatusComponent } from "./service-collaborative/service-status/service-status.component";
import { UIModule } from "./shared/ui/ui.module";
import { AuthInterceptor } from "./core/helpers/auth.Interceptor";

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    TooltipModalComponent,
    ModalCreateComponent,
    ModalDeleteComponent,
    ModalEditComponent,
    ApprovalModalComponent,
    DeleteModalComponent,
    IncluirModalComponent,
    DependensAccordionNewComponent,
    CreateServiceComponent,
    ServiceStatusComponent,
    CoverageModalComponent,
   PrivacyDataModalComponent,
  ],
  bootstrap: [AppComponent],
  providers: environment.enableEncrypit
    ? [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: decryptInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
        { provide: LOCALE_ID, useValue: "pt" },
      ]
    : [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
        { provide: LOCALE_ID, useValue: "pt" },
      ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgToggleModule,
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbTooltipModule,
    NgbToast,
    ScrollToModule.forRoot(),
    UIModule,
    NgbModule,
    PortalAdmissionModule,
  ],
})
export class AppModule {}
