import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthenticationService } from "../../core/services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { TenantStore } from "src/app/core/services/Stores/tenant.store";
import { CandidateStore } from 'src/app/core/services/Stores/candidate.store';
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { UtilsService } from "src/app/core/services/utils.service";
import { EmployeeStore } from "src/app/core/services/Stores/employee.store";
import { Observable, take, filter, switchMap } from "rxjs";
import { CompanyService } from 'src/app/core/services/company.service';

@Component({
  selector: 'app-candidate-portal-layout',
  templateUrl: './candidate-portal-layout.component.html',
  styleUrls: ['./candidate-portal-layout.component.scss']
})
export class CandidatePortalLayoutComponent implements OnInit {

    element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  username: string;
  nickname;
  waitingHRApproval$: Observable<any>;
  canManageEmployeeProfile: boolean;
  totalNotifications: Observable<any>;

  companyId: string;
  empresa: string;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        public languageService: LanguageService,
        public translate: TranslateService,
        public _cookiesService: CookieService,
        private utilsService: UtilsService,
        private employeeStore: EmployeeStore,
        public tenantStore:TenantStore,
        public candidateStore: CandidateStore,
        public companyService: CompanyService
      ) {}
    
      
  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

//   @Output() settingsButtonClicked = new EventEmitter();
//   @Output() mobileMenuButtonClicked = new EventEmitter();

  scrollToTop(): void {
    
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}


handleLogoClick(): void {
  const currentRoute = this.router.url; // Obtém a rota atual
  const homeRoute = `/${this.empresa}/candidate-portal`; // Define a rota da página inicial


  if (currentRoute === homeRoute) {
    // Se já estiver na home, rola para o topo
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  } else {
    // Caso contrário, navega para a home
    this.router.navigate([homeRoute]).then(() => {
      // Após a navegação, rola para o topo
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }
}

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;


    // const user = this.authService.currentUser();
    // this.username = user.payload.name;
    // this.nickname = this.utilsService.getUserNickname(this.username);

    // if (this.authService.canManageEmployeeProfile)
    //   this.employeeStore.getEmployeesWaitingHRApproval();

    // this.canManageEmployeeProfile = this.authService.canManageEmployeeProfile;
    // this.waitingHRApproval$ = this.employeeStore.waitingHRApproval.pipe(
    //   map((items) =>
    //     items.map((item) => ({
    //       ...item,
    //       nickname: this.utilsService.getUserNickname(item.name),
    //     }))
    //   )
    // );

    // this.totalNotifications = this.canManageEmployeeProfile
    //   ? this.waitingHRApproval$.pipe(map((items) => items.length))
    //   : of(null);

    
    
    this.getUrlQueryParams();
  }

  
    private getUrlQueryParams() {
  
      const urlSegments = this.router.url.split("/");
      const company_url = urlSegments[1];
  
      
      this.empresa = this.route.snapshot.paramMap.get("empresa") || company_url;
  
      // Chama getCompanyId e só executa loadVacancy após companyId ser definido
      const sub = this.getCompanyId(this.empresa).subscribe((params) => {
  
       
        this.candidateStore.applyStylePublic(this.empresa);
       
      });
  
     
    }
  
    private getCompanyId(empresa: string) {
      return this.companyService.getByURL(empresa).pipe(
        take(1),
        filter((res: any) => res && res.id), // Garante que só continua se o ID existir
        switchMap((res: any) => {
          this.companyId = res.id;
          console.log("Company ID:", this.companyId);
  
          
        //this.candidateStore.applyStylePublic(this.companyId);
        
          return this.route.params; // Continua com os parâmetros de rota após definir o ID
        })
      );
    }
  

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }
}
