import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  Observable,
  of,
  shareReplay,
  tap,
} from "rxjs";
import { environment } from "src/environments/environment";
import { GetEmployeesModel } from "../../models/get-employees.model";
import { LatestEmployeesWaitingHRApprovalModel } from "../../models/LatestEmployeesWaitingHRApprovalModel";
import { AppToastService } from "../app-toast.service";
import { GetJourney } from "../../models/journeys/get-journey.model";
import { GetJourneyById } from "../../models/journeys/get-journey-by-id.model";

@Injectable({ providedIn: "root" })
export class EmployeeStore {
  private employeeUrl = `${environment.url}/v1/employees`;
  private employeesSubject = new BehaviorSubject<GetEmployeesModel[]>([]);
  private latestEmployeesWaitingHRApproval = new BehaviorSubject<
    LatestEmployeesWaitingHRApprovalModel[]
  >([]);

  public allEmployees$ = this.employeesSubject.asObservable();
  public waitingHRApproval =
    this.latestEmployeesWaitingHRApproval.asObservable();

  constructor(private http: HttpClient, private toast: AppToastService) {
    // this.getEmployees();
  }

  public getEmployees() {
    this.http
      .get<GetEmployeesModel[]>(this.employeeUrl)
      .pipe(
        shareReplay(),
        catchError((error) => of([])),
        tap((employees) => this.employeesSubject.next(employees))
      )
      .subscribe();

    return this.employeesSubject.asObservable();
  }

  public getEmployeesWaitingHRApproval() {
    this.http
      .get<LatestEmployeesWaitingHRApprovalModel[]>(
        `${this.employeeUrl}/profiles/latest-update`
      )
      .pipe(
        shareReplay(),
        tap((employees) =>
          this.latestEmployeesWaitingHRApproval.next(employees)
        )
      )
      .subscribe();
  }

  approve(id) {
    const endpoint = `${this.employeeUrl}/${id}/profile/approved`;
    return this.http
      .post(endpoint, null)
      .pipe(tap(() => this.updateEmployeesWaitingApprovalNotification(id)));
  }

  approveWithVacancy(id, vacancyId) {
    if (!vacancyId) {
      return this.approve(id);
    }
    const endpoint = `${this.employeeUrl}/${id}/${vacancyId}/profile/approved`;
    return this.http
      .post(endpoint, null)
      .pipe(tap(() => this.updateEmployeesWaitingApprovalNotification(id)));
  }

  disapprove(id: number, payload) {
    const endpoint = `${this.employeeUrl}/${id}/profile/repproved`;
    return this.http
      .post(endpoint, payload)
      .pipe(tap(() => this.updateEmployeesWaitingApprovalNotification(id)));
  }

  sendToHR(id) {
    const endpoint = `${this.employeeUrl}/${id}/profile/completed`;
    return this.http.post(endpoint, {});
  }

  canSendToHR(id) {
    const endpoint = `${this.employeeUrl}/${id}/profile/completed/enabled`;
    return this.http.get<boolean>(endpoint, {});
  }

  requestMovement = (id: number, body: any) => {
    const endpoint = `${this.employeeUrl}/${id}/moves`;

    return this.http
      .post(endpoint, body)
      .subscribe(() =>
        this.toast.show(
          "Movimentação de colaborador",
          "ação realizada com sucesso, acompanhe o status no menu atendimento."
        )
      );
  };

  getEmployeeJourneyWork(id): Observable<GetJourney> {
    return this.http.get<GetJourney>(`${this.employeeUrl}/${id}/work-journey`);
  }

  private updateEmployeesWaitingApprovalNotification = (employeeId) => {
    const latestEmployees = this.latestEmployeesWaitingHRApproval.getValue();
    const updated = latestEmployees.filter((item) => item.id != employeeId);
    this.latestEmployeesWaitingHRApproval.next([...updated]);
  };
}
