<section class="container-fluid p-0" id="account-container">
  <article class="row justify-content-center">
    <div class="col-lg-8 col-sm-7 p-0 d-sm-none d-md-block">
      <div class="background-auth"></div>
    </div>
    <div class="col-lg-4 col-sm-8 px-sm-4 px-lg-5 px-5">
      <router-outlet></router-outlet>
    </div>
  </article>
</section>
