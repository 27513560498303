<header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex align-items-center">
      
        <div class="navbar-brand-box">
        
  
          <a class="logo logo-light d-flex" (click)="handleLogoClick()">
            <span class="logo-sm">
             
              <ng-container *ngIf="candidateStore.style$ | async as style">
                <img [src]="style.LogoTop" alt="logo-top" height="22" />
              </ng-container>
            </span>
            <span class="logo-lg">
              
              <ng-container *ngIf="candidateStore.style$ | async as style">
                <img
                  [src]="style.LogoTop"
                  alt="logo-top"
                  [height]="style.LogoTopHeight || 40"
                />
              </ng-container>
            </span>
          </a>
        </div>
      </div>
  
    
    </div>
  </header>
  
  
  <div class="layout-container">
   
  
    <main class="content">
      <router-outlet></router-outlet>
    </main>
  </div>
  