<div class="upload-content">
  <div >
    <ng-container *ngIf="!selectedImage && !selectedPdf">
      <input type="file" hidden #fileDropRef (change)="handleFileSelect($event)" multiple />
      <i class="mdi mdi-cloud-upload"></i>
      <p *ngIf="!dragged; else notifyLoading">
        clique
        <button
          type="button"
          class="btn p-0 text-primary"
          (click)="fileDropRef.click()"
          [disabled]="isExhibition"
        >
          aqui para selecionar.
        </button>
        <small class="d-block">formatos validos: {{getAllowedFormats}}</small>
        <span class="text-danger d-block" *ngIf="showInvalidFileFormat">Formato selecionado inválido.</span>
        <span class="text-danger d-block" *ngIf="showFileSizeExceededError">O arquivo selecionado é muito grande. tamanho máximo aceito é {{fileSizeLimit | kbToMb}} e o tamanho do arquivo é {{this.currentFile.size | kbToMb }}</span>
        <span class="text-danger d-block" *ngIf="showExceededFileLimit">O número máximo de {{ maxFiles }} arquivos foi excedido.</span>
      </p>
      <ng-template #notifyLoading>
        <p #notifyLoading>processando imagem...</p>
      </ng-template>
    </ng-container>

    <!-- {{aaa}} -->
    <div
      class="position-relative p-2"
      *ngIf="
        (selectedImage || selectedPdf) &&
        (selectedImage !== undefined || selectedPdf !== undefined)
      "
    >
      <img
        class="img-upload"
        *ngIf="selectedImage"
        [src]="selectedImage"
        alt="Imagem carregada"
        (click)="openModal()"
        (error)="errorHandler($event)"

      />

      <div class="pdf" *ngIf="selectedPdf">
        <img src="../../../../assets/images/pdf-logo.png" alt="" /><br />
        <span class="my-2">{{ fileName }}</span>
      </div>

      <button
        class="btn btn-outline-danger position-absolute bottom-0 end-0 m-2"
        (click)="removeFile()"
      >
        <i class="mdi mdi-delete-outline"></i>
      </button>
    </div>
    <!-- Modal para exibir a imagem -->

    <app-new-modal [modalOpen]="showModal" (closeModalEvent)="closeModal()" >
      <div class="pdf-content"(keydown)="onKeyDown($event)" >
        <img
          class="expanded-image"
          *ngIf="selectedImage"
          [src]="selectedImage"
          alt="Imagem carregada"
        />
      </div>
    </app-new-modal>
  </div>
</div>
