import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  find,
  map,
  of,
  take,
  takeLast,
  shareReplay,
  distinctUntilChanged,
  tap,
} from "rxjs";
import { SkillModel } from "../../models/skill.model";
import { VacancyModel } from "../../models/vacancy.model";
import { AppToastService } from "../app-toast.service";
import { UtilsService } from "../utils.service";
import { Router } from "@angular/router";

import { TenantStyleConfigModel } from "../../models/tenant-style-config.model";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth.service";

import { CandidateModel } from "../../models/candidate.model";
import { VacancyCardModel } from "../../models/vacancy-card.model";
import { CandidateVacancyCardModel } from "../../models/candidate-vacancy-card.model";
//import { CandidateVacancyCardModel } from "../../models/candidate-vacancy-card.model";
import { CandidateCardModel } from "../../models/candidate-card.model";
import { GetCandidateByIdResponse } from "../../models/candidate-view.model";


@Injectable({ providedIn: "root" })
export class CandidateStore {

  private styleConfigSubject = new BehaviorSubject<TenantStyleConfigModel>(
    defaultStyle
  );

  public email: string;

  private url = `${environment.url}/v1/candidates`;
  private vacancyUrl = `${environment.url}/v1/vacancies`;
  private baseUrl = `${environment.url}/v1/tenants`;

  private allVacanciesSubject = new BehaviorSubject<VacancyCardModel[]>(null);
  private filteredVacanciesSubject = new BehaviorSubject<VacancyCardModel[]>(null);

  private allCandidatesSubject = new BehaviorSubject<CandidateCardModel[]>(null);
  private filteredCandidatesSubject = new BehaviorSubject<CandidateCardModel[]>(null);

  private justificationsSubject = new BehaviorSubject<unknown[]>(null);
  private skillsSubject = new BehaviorSubject<SkillModel[]>(null);
  private citiesSubject = new BehaviorSubject<unknown[]>(null);
  private countriesSubject = new BehaviorSubject<unknown[]>(null);
  private showVacancyPreviewSubject = new BehaviorSubject<boolean>(null);
  private reviewesSubject = new BehaviorSubject<unknown[]>(null);
  //private newVacancySubject = new BehaviorSubject<VacancyModel>(null);
  private vacancyPreviewSubject = new BehaviorSubject<unknown>(null);
  private companySubject = new BehaviorSubject<unknown>(null);


  public style$: Observable<TenantStyleConfigModel> =
    this.styleConfigSubject.asObservable();

  public vacanies$: Observable<VacancyCardModel[]> =
    this.filteredVacanciesSubject.asObservable();

  public candidate$: Observable<CandidateCardModel[]> =
    this.filteredCandidatesSubject.asObservable();

  public showPreview$: Observable<boolean> =
    this.showVacancyPreviewSubject.asObservable();

  public vacancyPreview$ = this.vacancyPreviewSubject.asObservable();
  public company$ = this.companySubject.asObservable();

  constructor(
    private auth: AuthenticationService,
    //private utilService: UtilsService,
    private http: HttpClient,
    private router: Router,
    private toast: AppToastService,
    private utils: UtilsService
  ) { }

  getStyles(id?: number) {
    if (id) {
      this.http
        .get<TenantStyleConfigModel>(`${this.baseUrl}/${id}`)
        .pipe(
          map((data: any) =>
            data.map((item) => ({ key: item.key, value: item.value }))
          ),
          map((data) => data.map((item) => Object.values(item))),
          map((data) => {
            const config = {};
            data.forEach((item) => (config[item[0]] = item[1]));
            return config as TenantStyleConfigModel;
          }),
          tap((data) =>
            Object.keys(data).length
              ? this.styleConfigSubject.next({ ...defaultStyle, ...data })
              : this.styleConfigSubject.next(defaultStyle)
          )
        )
        .subscribe();
    }
  }

  getStylesPublic(empresa?: string) {
    if (empresa) {
      this.http
        .get<TenantStyleConfigModel>(`${this.baseUrl}/${empresa}/public`)
        .pipe(
          map((data: any) =>
            data.map((item) => ({ key: item.key, value: item.value }))
          ),
          map((data) => data.map((item) => Object.values(item))),
          map((data) => {
            const config = {};
            data.forEach((item) => (config[item[0]] = item[1]));
            return config as TenantStyleConfigModel;
          }),
          tap((data) =>
            Object.keys(data).length
              ? this.styleConfigSubject.next({ ...defaultStyle, ...data })
              : this.styleConfigSubject.next(defaultStyle)
          )
        )
        .subscribe();
    }
  }


  setStyles(json: string, companyId: number) {
    const payload = JSON.parse(json);
    return this.http.post(`${this.baseUrl}/${companyId}`, payload);
  }

  setLogo(json: string, companyId: number) {
    const payload = JSON.parse(json);
    return this.http.post(`${this.baseUrl}/${companyId}/logos`, payload);
  }

  applyStylePublic(id?: string) {
    this.getStylesPublic(id);
    this.style$.subscribe((style) => {
      document.documentElement.style.setProperty(
        "--vertical-menu-color",
        style.VerticalMenuColor || defaultStyle.VerticalMenuColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-color",
        style.VerticalMenuItemColor || defaultStyle.VerticalMenuItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-sub-item-color",
        style.VerticalMenuSubItemColor || defaultStyle.VerticalMenuSubItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-icon-color",
        style.VerticalMenuIconColor || defaultStyle.VerticalMenuIconColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-hover-color",
        style.VerticalMenuItemHoverColor ||
        defaultStyle.VerticalMenuItemHoverColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-active-color",
        style.VerticalMenuItemActiveColor ||
        defaultStyle.VerticalMenuItemActiveColor
      );
      document.documentElement.style.setProperty(
        "--horizontalbar-color",
        style.horizontalBarColor || defaultStyle.horizontalBarColor
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        style.PrimaryColor || defaultStyle.PrimaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        style.SecondaryColor || defaultStyle.SecondaryColor
      );
      document.documentElement.style.setProperty(
        "--gradient-color",
        style.GradientColor || defaultStyle.GradientColor
      );
      document.documentElement.style.setProperty(
        "--default-label-color",
        style.LabelColor || defaultStyle.LabelColor
      );
    });
  }


  applyStyle() {
    this.getStyles(this.auth.getCurrentUser?.companyId);
    this.style$.subscribe((style) => {
      document.documentElement.style.setProperty(
        "--vertical-menu-color",
        style.VerticalMenuColor || defaultStyle.VerticalMenuColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-color",
        style.VerticalMenuItemColor || defaultStyle.VerticalMenuItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-sub-item-color",
        style.VerticalMenuSubItemColor || defaultStyle.VerticalMenuSubItemColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-icon-color",
        style.VerticalMenuIconColor || defaultStyle.VerticalMenuIconColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-hover-color",
        style.VerticalMenuItemHoverColor ||
        defaultStyle.VerticalMenuItemHoverColor
      );
      document.documentElement.style.setProperty(
        "--vertical-menu-item-active-color",
        style.VerticalMenuItemActiveColor ||
        defaultStyle.VerticalMenuItemActiveColor
      );
      document.documentElement.style.setProperty(
        "--horizontalbar-color",
        style.horizontalBarColor || defaultStyle.horizontalBarColor
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        style.PrimaryColor || defaultStyle.PrimaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        style.SecondaryColor || defaultStyle.SecondaryColor
      );
      document.documentElement.style.setProperty(
        "--gradient-color",
        style.GradientColor || defaultStyle.GradientColor
      );
      document.documentElement.style.setProperty(
        "--default-label-color",
        style.LabelColor || defaultStyle.LabelColor
      );
    });
  }





  public getAllVacancyWithPage(companyId: string, currentPage: number, totalPerPage: number): Observable<{ data: { total: number; content: CandidateVacancyCardModel[] } }> {
    return this.http.get<{ data: { total: number; content: CandidateVacancyCardModel[] } }>(`${this.url}/vagas`, {
      params: {
        companyId,
        CurrentPage: currentPage.toString(),
        TotalPerPage: totalPerPage.toString(),
      },
    });
  }

  // public getAll(id?: string) {
  //   this.http
  //     .get<VacancyCardModel[]>(`${this.vacancyUrl}/${id}/company`)
  //     .pipe(
  //       shareReplay(),
  //       tap(this.setNicknameToReviewers),
  //       tap((vacancies) => this.allVacanciesSubject.next(vacancies)),
  //       tap((vacancies) => this.filteredVacanciesSubject.next(vacancies))
  //     )
  //     .subscribe();
  //   return this.filteredVacanciesSubject.asObservable();
  // }

  //candidate

  public getAllNew(
    companyId: string,
    vacancyId: string,
    area_cargo: string,
    statusId: string
  ): Observable<CandidateCardModel[]> {
    return this.http
      .get<{ data: { content: CandidateCardModel[]; total: number } }>(
        `${this.url}/all`,
        {
          params: {
            companyId,
            vacancyId,
            area_cargo,
            statusId,
          },
        }
      )
      .pipe(
        tap((response) => {
          // Verifica se o conteúdo da resposta é válido
          if (Array.isArray(response.data.content)) {
            this.allCandidatesSubject.next(response.data.content);
            this.filteredCandidatesSubject.next(response.data.content);
          } else {
            console.error(
              "Conteúdo inválido retornado pela API:",
              response.data.content
            );
            this.allCandidatesSubject.next([]);
            this.filteredCandidatesSubject.next([]);
          }
        }),
        map((response) => response.data.content), // Retorna apenas os candidatos
        shareReplay()
      );
  }

  /*
  public getAll(companyId: string, vacancyId: string, area_cargo: string, statusId: string) {
    this.http
    .get<CandidateCardModel[]>(`${this.url}/all`, {
      params: {
        companyId: companyId,
        vacancyId,
      area_cargo,
      statusId,
      },
    })
    .pipe(
      shareReplay(),
      //tap(this.setNicknameToReviewers),
      tap((candidates) => this.allCandidatesSubject.next(candidates)),
      tap((candidates) => this.filteredCandidatesSubject.next(candidates)),
    )
    .subscribe();
    return this.filteredCandidatesSubject.asObservable();
  }*/


  public getAll(companyId: string, vacancyId: string, area_cargo: string, statusId: string): Observable<{ data: { total: number; content: CandidateCardModel[] } }> {
    return this.http.get<{ data: { total: number; content: CandidateCardModel[] } }>(`${this.url}/all`, {
      params: {
        companyId,
        vacancyId,
        area_cargo,
        statusId,
      },
    });
  }


  public getAllWithPage(companyId: string, vacancyId: string, area_cargo: string, statusId: string, currentPage: number, totalPerPage: number): Observable<{ data: { total: number; content: CandidateCardModel[] } }> {
    return this.http.get<{ data: { total: number; content: CandidateCardModel[] } }>(`${this.url}`, {
      params: {
        companyId,
        vacancyId,
        area_cargo,
        statusId,
        CurrentPage: currentPage.toString(),
        TotalPerPage: totalPerPage.toString(),
      },
    });
  }

  public getAllWithPageOld(companyId: string, vacancyId: string, area_cargo: string, statusId: string, currentPage: number, totalPerPage: number) {


    this.http
      .get<CandidateCardModel[]>(`${this.url}`, {
        params: {
          companyId: companyId,
          vacancyId,
          area_cargo,
          statusId,
          CurrentPage: currentPage.toString(),
          TotalPerPage: totalPerPage.toString(),
        },
      })
      .pipe(
        shareReplay(),
        //tap(this.setNicknameToReviewers),
        tap((candidates) => this.allCandidatesSubject.next(candidates)),
        tap((candidates) => this.filteredCandidatesSubject.next(candidates))
      )
      .subscribe();
    return this.filteredCandidatesSubject.asObservable();
  }


  /*
    public getAllWithPageOld(companyId: string, currentPage: number, totalPerPage: number) {


      this.http
        .get<CandidateVacancyCardModel[]>(`${this.url}/vagas`, {
          params: {
            companyId: companyId,
            CurrentPage: currentPage.toString(),
            TotalPerPage: totalPerPage.toString(),
          },
        })
        .pipe(
          shareReplay(),
          //tap(this.setNicknameToReviewers),
          tap((vacancies) => this.allVacanciesSubject.next(vacancies)),
          tap((vacancies) => this.filteredVacanciesSubject.next(vacancies))
        )
        .subscribe();
      return this.filteredVacanciesSubject.asObservable();
    }*/



  public applyFilter(filters: any) {
    const vacancies = this.allVacanciesSubject.getValue();
    const filteredVacancies = vacancies
      .filter((v) => (!filters.draft ? v.status !== 1 : true))
      .filter((v) => (!filters.waitingApproval ? v.status !== 2 : true))
      .filter((v) => (!filters.waitingPublish ? v.status !== 3 : true))
      .filter((v) => (!filters.suspended ? v.status !== 4 : true))
      .filter((v) => (!filters.canceled ? v.status !== 5 : true))
      .filter((v) => (!filters.reproved ? v.status !== 6 : true))
      .filter((v) => (!filters.approved ? v.status !== 7 : true));
    this.filteredVacanciesSubject.next(filteredVacancies);
  }

  getVacancy(id: number) {
    return this.http
      .get(`${this.vacancyUrl}/${id}`)
      .pipe(shareReplay(), distinctUntilChanged(), map(this.mapToForm));
  }


  getCompanyByURL(empresa: string) {
    return this.http
      .get(`${this.url}/${empresa}/company`)
      .pipe();
  }


  public showPreview(vacancyId: string, companyId: number) {
    const url = `${this.url}/${vacancyId}/preview`;

    const service = this.http
      .get<{ message: string; data: any }>(url, { params: { companyId: companyId.toString() } })
      .pipe(
        tap((response) => this.vacancyPreviewSubject.next(response.data))
      );

    service.subscribe(); // Mantém a execução do efeito colateral

    return this.vacancyPreviewSubject.asObservable();
  }

  /* //MUDANDO PARA NOVO MÉTODO DA API
   public showPreview(vacancyId, companyId: number) {
     const url = `${this.vacancyUrl}/${vacancyId}/public_preview`;
     const service = this.http
       .get(url, {params: {companyId: companyId }})
       .pipe(tap((preview) => this.vacancyPreviewSubject.next(preview)));

     service.subscribe();

     return this.vacancyPreviewSubject.asObservable();
   }*/

  public createCandidateRequest(payload: Partial<CandidateModel>): Observable<any> {
    return this.http.post(`${this.url}`, payload).pipe(
      tap(() => {
        this.toast.show(
          "Sucesso!",
          "Candidatura efetivada com sucesso."
        );
      })
    );
  }

  // public createCandidateRequest(payload: Partial<CandidateModel>) {


  //   return this.http.post(`${this.url}`, payload).subscribe({
  //     next: () => {
  //       this.toast.show(
  //         "Sucesso!",
  //         "Candidatura efetivada com sucesso."
  //       );

  //       console.log("save()")

  //     },
  //   });


  // }


  public save(candidate: Partial<CandidateModel>) {


    return this.http.post(this.url, candidate);

    /*
      return this.http
        .post(this.url, { candidate })
        .pipe(
          tap(() =>
            this.toast.show("Sucesso!", "Candidatura efetivada com sucesso.")
          )
        );*/

    /*

       this.http
      .post(this.url, { ...candidate })
      .pipe(
        tap(() =>
          this.toast.show("Sucesso!", "Candidatura efetivada com sucesso."
          ))
        );
    */

    // this.toast.show(
    //     "Sucesso!",
    //     "Candidatura efetivada com sucesso."
    //   );

    console.log("save()")

  }


  private mapToForm(vacancy: any) {
    const {
      branchId,
      roleId,
      description,
      benefitsId,
      salary,
      educationLevelId,
      experienceId,
      languagesId,
      especializationId,
      bindTypeId,
      workTypeId,
      workLoadId,
      countryId,
      cityId,
      createdAt,
      deadlineDate,
      forecastDate,
      requirements,
      skillsId,
      status,
      reviewersId,
      justificationId,
      requesterId,
      isPcd,
      creatorId,
      id,
    } = vacancy;

    const settingsData = {
      id: id,
      branches: branchId,
      role: roleId,
      description: description,
      roleBenefits: benefitsId,
      salary: salary,
      educationLevelId: educationLevelId,
      experienceId: experienceId,
      languageId: languagesId,
      languagesId: [...languagesId],
      especialization: especializationId,
      bindTypeId: bindTypeId,
      workDayId: workLoadId,
      workModelId: workTypeId,
      state: countryId,
      city: cityId,
      createdAt: createdAt,
      deadlineDate: deadlineDate,
      forecastDate: forecastDate,
      requirements: requirements,
      skills: skillsId,
      status: status,
      assessments: reviewersId,
      isPcd: isPcd
    };
    const justificationData = {
      justification: justificationId,
      requester: requesterId,
      creatorId: creatorId,
    };
    return { settingsData, justificationData };
  }

  private setReviewers(reviewersId: number[]) {
    this.reviewesSubject.next(reviewersId);
  }

  // private mapToModel(id: number, settingsData: any, justificationData: any) {
  //   const {
  //     branches,
  //     role,
  //     roleBenefits,
  //     description,
  //     salary,
  //     educationLevelId,
  //     experienceId,
  //     especialization,
  //     languagesId,
  //     bindTypeId,
  //     workModelId,
  //     workDayId,
  //     state,
  //     city,
  //     createdAt,
  //     deadlineDate,
  //     forecastDate,
  //     requirements,
  //     skills,
  //     assessments,
  //     isPcd
  //   } = settingsData;
  //   const { requester, justification, directSupervisor } =
  //     justificationData || {};

  //   const vacancy: VacancyModel = {
  //     id: id || null,
  //     branchId: branches,
  //     roleId: role,
  //     description: description,
  //     roleBenefitsId: roleBenefits,
  //     salary: salary,
  //     educationLevelId: educationLevelId,
  //     experienceId: experienceId,
  //     languagesId: languagesId ? [...languagesId] : [],
  //     especializationId: especialization,
  //     bindTypeId: bindTypeId,
  //     workloadId: workDayId,
  //     workTypeId: workModelId,
  //     countryId: state,
  //     cityId: city,
  //     createdAt: createdAt,
  //     deadlineDate: deadlineDate,
  //     forecastDate: forecastDate,
  //     requirements: requirements,
  //     skillsId: skills,
  //     status: 1,
  //     reviewersId: assessments,
  //     justificationId: justification,
  //     directSupervisorId: directSupervisor,
  //     requesterId: requester,
  //     isPcd: isPcd,
  //     qtdVacancies: qtdVacancies
  //   };
  //   return vacancy;
  // }

  private setNicknameToReviewers = (vacancies: VacancyCardModel[]): void =>
    vacancies.forEach((vacancy) =>
      vacancy.reviewers.forEach(
        (r) => (r.nickName = this.utils.getUserNickname(r.name))
      )
    );

  public getCandidateById(id: number): Observable<GetCandidateByIdResponse> {
    return this.http.get<GetCandidateByIdResponse>(`${this.url}/${id}`);
  }
}


export const defaultStyle: TenantStyleConfigModel = {
  TenantName: "N/A",
  LogoBottom: "",
  LogoTop: "assets/images/meurh360-white-logo1.png",
  TenantCode: "",
  GradientColor: "",
  horizontalBarColor: "",
  PrimaryColor: "#2979FF",
  LabelColor: "#1B4EA3",
  SecondaryColor: "#74788d",
  VerticalMenuColor: "#2a3042",
  VerticalMenuItemColor: "#a6b0cf",
  VerticalMenuSubItemColor: "#79829c",
  VerticalMenuIconColor: "#6a7187",
  VerticalMenuItemHoverColor: "#FFFFFF",
  VerticalMenuItemActiveColor: "#FFFFFF",
  logoTopHeight: 30,
};



export interface TenantConfiguration {
  id: number;
  key: string;
  value: string;
  active: boolean;
  type: number;
}




