<style>
  .modal-dialog { 
      max-width: 640px !important;
  }
</style>
<div class="modal-header border-bottom-0">
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-content custom-modal">
  <div class="row text-left">
    
    
    <div class="col-12">
      <h4 class="title-t" *ngIf="modal.title">{{ modal.title }}</h4>
      <p class="text-competence mt-3">
        Ao candidatar-se a uma vaga e enviar seu currículo, você concorda com nossa Política de Governança de Proteção de Dados Pessoais e com o Termo de Privacidade da Companhia disponível no site <a href="https://csdbr.com/" target="_blank">https://csdbr.com/</a>
          <br/><br/>
          Ao fornecer seu currículo, você nos autoriza a processar e armazenar seus dados para fins de avaliação de candidatos para recrutamento e seleção de talentos, para esta e para futuras oportunidades alinhadas ao seu perfil. Suas informações serão utilizadas e mantidas de acordo com a Lei Geral de Proteção de Dados Pessoais e com os documentos mencionados, sendo assim, pedimos gentilmente que leia-os cuidadosamente antes de prosseguir.
          <br/><br/>
          Caso tenha dúvidas ou queira exercer seus direitos como titular de dados, entre em contato com nosso Encarregado pelo e-mail <a href="mailto:dpo@csdbr.com">dpo@csdbr.com</a>.
      </p>
    </div>
    <div class="col-md-12 mt-2">
      <div class="col-md-12 text-align-center">
        <button
          type="button"
          class="btn btn-secondary m-1 b"
          (click)="closeModal()"
        >
          Voltar
        </button>
      </div>
    </div>
  </div>
</div>
